import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import HeadingTextTwoColumns from "../components/HeadingTextTwoColumns/headingTextTwoColumns"
import Heading from "../components/Heading/heading"
import ImageTextBlock from "../components/ImageTextBlock/imageTextBlock"
import Map from "../components/Map/map"
import FactsList from "../components/FactsList/factsList"
import Button from "../components/Button/Button"

import { NavLink } from "../styles/typography"
import { useGlobalAptSelectorOpen } from "../globalState"

const IndexPage = () => {
  const [, setAptSelectorOpen] = useGlobalAptSelectorOpen()
  return (
    <Layout>
      <Seo
        title="Smedjan Tredje Lång - Kommande Nyproduktion Göteborg"
        description="Passa på att skriva upp dig på vår kommande nyproduktion i Göteborg. Smedjan byggs om till attraktiva & moderna lägenheter på Tredje långgatan. Läs mer!"
      />
      <div style={{ position: "relative", marginTop: `2.45rem` }}>
        <StaticImage
          src="../images/porthuset-gardshuset-cropped.jpg"
          quality={95}
          layout="fullWidth"
          formats={["AUTO", "WEBP"]}
          alt="Smedjans två hus"
        />
        <NavLink
          to="/gardshuset/"
          duration={1}
          style={{ position: "absolute", top: "30%", left: "45%" }}
        >
          Gårdshuset
        </NavLink>{" "}
        <NavLink
          to="/porthuset/"
          duration={1}
          style={{ position: "absolute", top: "60%", left: "30%" }}
        >
          Porthuset
        </NavLink>
      </div>
      <HeadingTextTwoColumns
        heading="Ett samspel mellan Tredje Långgatans industriella historia och samtida arkitektur"
        hasBuyButton
        text={[
          "Fastigheten Smedjan är ett möte mellan långgatornas småskaliga industrihistoria och vår tids sparsmakade arkitektur. Vi tar vara på och utvecklar det bästa av två världar för att skapa en unik fastighet som består av två karakteristiska byggnader.",
          "Fyra stadsradhus i O.A. Möllers låga smedja samsas med åtta glasade våningar innehållandes 1-2 rum och kök som suddar ut gränsen mellan inne och ute. ",
          "Det är gedigen bostadskvalitet med material som är förankrade i platsen och åldras med värdighet. Det är omsorgsfullt balanserade ytterligheter. Högt möter lågt. Hårda material möter varma ytor. Historia flätas samman med vår samtid.",
          "Lägenheterna är kvadratmetersmart planerade med genomgående gedigna materialval, omsorgsfullt utvalda detaljer och harmoniska proportioner. Till bostadsrätterna finns även tillgång till en gemensam takterrass i söderläge med utsikt över Skansen Kronan, samt en fin gård mellan byggnaderna.",
          "Välkommen till en historisk och kreativ adress mitt i det sjudande folklivet på långgatorna.",
        ]}
        wide={false}
      />

      <StaticImage
        src="../images/bedroom.jpg"
        quality={95}
        layout="fullWidth"
        formats={["AUTO", "WEBP"]}
        alt="Sovrum"
      />

      <FactsList
        facts={[
          { title: "Boarea", info: "22-73 kvm" },
          { title: "Rum", info: "1-4 rum och kök" },
          { title: "Pris", info: "1 700 000 kr – 6 650 000 kr" },
          { title: "Avgift", info: "1476-3917kr/mån" },
          { title: "Tillträde", info: "Prel. December 2024" },
          { title: "Antal lägenheter", info: "39" },
          { title: "Upplåtelseform", info: "Bostadsrätt" },
          { title: "Hiss", info: "Ja" },
          { title: "Uppvärmning", info: "FTX med 85% \nvärmeåtervinning" },
          { title: "Adress", info: "Tredje Långgatan 26" },
        ]}
      />

      <Heading text="Historia" level="h2withH1style" position="right" />
      <ImageTextBlock
        preHeading="Ett nytt kapitel i historien om O.A. Möllers smidesverkstad"
        text={[
          `År 1897 övertar Olof Anton Möller en verkstad på Breda vägen, nuvarande Fjärde Långgatan. Efter några år flyttar han in i nya byggnader på Tredje Långgatan 26. Med sitt korta telefonnummer 2774 är det enkelt att slå en signal om man vill ha hjälp med järnkonstruktioner, plåtslageriarbeten eller mekaniska reparationer. Vid den här tiden är O.A. Möller Smidesverkstad särskilt duktiga på ornament och byggnadssmiden. "Allt utföres med tillhjälp af modernaste maskiner, hvarför moderata priser kan utlovas".`,
        ]}
        imagePosition="right"
        offset={2}
        buttonTitle="Mer om historia"
        buttonLink="/historia/"
        image={
          <StaticImage
            src="../images/historia.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="historia"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <Heading
        number={1}
        text="Porthuset"
        level="h2withH1style"
        position="left"
        offset={true}
      />
      <ImageTextBlock
        text={[
          `Längs gatan kommer Porthusets fyra radhus om 2,5 etage att ligga. De är helt ensamma i sitt slag, med inspiration från ett så kallat Town House. Begreppet kommer ursprungligen från de engelska adelsmännens adresser inne i stan. De behövde övernatta efter dagens affärer och kvällens besök på klubben, innan de återvände till sina gods.`,
        ]}
        imagePosition="left"
        offset={2}
        buttonTitle="Mer om Porthuset"
        buttonLink="/porthuset/"
        image={
          <StaticImage
            src="../images/porthuset-fasad.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Porthuset"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />
      <Heading
        number={2}
        text="Gårdshuset"
        level="h2withH1style"
        position="right"
      />
      <ImageTextBlock
        text={[
          `Det åtta våningar höga Gårdshuset kommer att torna upp sig bakom Porthusets anspråkslösa fasad. Byggnadens två – i djupled lätt förskjutna – element får en fasad i natursten. Med pelare och balkar skapas ett rutmönster som ramar in lägenheternas heltäckande glasväggar. Fasaderna avslutas med en brant diagonal i höjd med de två översta våningarna. Häruppe finns också en gemensam takterrass.`,
        ]}
        imagePosition="right"
        offset={2}
        buttonTitle="Mer om Gårdshuset"
        buttonLink="/gardshuset/"
        image={
          <StaticImage
            src="../images/gardshuset-fasad.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Gårdshuset"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <div style={{ position: "relative", marginBottom: `3.45rem` }}>
        <StaticImage
          src="../images/bostadsvaljare.png"
          quality={95}
          layout="fullWidth"
          formats={["AUTO", "WEBP"]}
          alt="Bostadsväljare"
        />
        <div
          style={{
            position: "absolute",
            top: "calc(50% - 23px)",
            left: "calc(50% - 125px)",
          }}
        >
          <Button
            type="button"
            onClick={() => setAptSelectorOpen(true)}
            title="Öppna bostadsväljare"
          />
        </div>
      </div>

      <Heading
        text="Typlägenheter"
        level="h2withH1style"
        position="right"
        offset={false}
      />
      <ImageTextBlock
        preHeading="1 rok, 1,5 rok, 2 rok, 3 rok, 4 rok."
        text={[
          `Compact living om ett rum och kök för den medvetne eller stadsradhus i flera etage för den kvadratsmarta familjen. Vilken typ av bostad söker du?`,
        ]}
        imagePosition="right"
        offset={1}
        customButtons
        image={
          <StaticImage
            src="../images/lagenhetsritning.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Planritning"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <Heading
        text="Material & Tillval"
        level="h2withH1style"
        position="left"
      />
      <ImageTextBlock
        preHeading="Färgval"
        text={[
          `Lägenheternas ljusa färgskalor är tidlöst vackra och naturnära. Väggarna går i en greige nyans med en svag hint av rött som bidrar med värme. Det ger en mjuk kontrast till det vita i taken och på listverken. Ett kök i en fröfärgad, greige kulör får fin kontrast med ett lite mörkare golv. En blå kulör i ett annat kök tar in havet tillsammans med ett ljust golv. Badrummen är inspirerade av den gotländska kalkstenen som följer upp tonerna i fasadens ljusa natursten.`,
        ]}
        imagePosition="left"
        offset={2}
        buttonTitle="Mer om material & tillval"
        buttonLink="/material/"
        image={
          <StaticImage
            src="../images/material-tillval.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Material"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <Heading text="Funktioner" level="h2withH1style" position="right" />
      <ImageTextBlock
        preHeading="Terrassfönster"
        text={[
          `De unika fönstren Svalson Open på Gårdshuset är ett helt nytt, vertikalgående och eldrivet fönstersystem. Med sina glasytor från golv till tak och vägg till vägg ges rummet maximalt ljusinsläpp och möjligheten att nyttja rummets hela golvyta då inga dörrar eller fönster behöver öppnas inåt och förstöra möbleringen, allt för att kunna maximera rummets användbarhet och kvadratmeter.`,
          `Fönstret består av ett fast nedre glasparti och ett vertikalt rörligt övre glasparti, som gör det möjligt att förvandla hela lägenheten till en terrass.`,
        ]}
        imagePosition="right"
        offset={2}
        buttonTitle="Mer om olika funktioner"
        buttonLink="/funktioner/"
        image={
          <StaticImage
            src="../images/newhouse.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Funktioner"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <Heading text="Området" level="h2withH1style" position="left" />
      <ImageTextBlock
        preHeading="The Guardian"
        text={[
          `I Göteborg är Smedjans kvarter redan kända för sitt levande kultur-, bar-, restaurang-, butiks- och klubbliv. Ändå blev nog en och annan lite förvånad när tidningen The Guardian utnämnde Järntorget och Långgatorna till det i särklass coolaste området i hela Europa. The Guardian tipsade om bra skivaffärer, Pusterviks livemusik och klubbkvällar samt Folkteatern. Bra mat hittade de på Tacos & Tequila och Kafé Magasinet. Soho Beer House eller Dugges (Brewers Beer Bar) fick bra omdöme för sin öl.`,
        ]}
        imagePosition="left"
        offset={2}
        buttonTitle="Mer om området"
        buttonLink="/omradet/"
        image={
          <StaticImage
            src="../images/omrade.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Mer om området"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <Heading text="Om oss" level="h2withH1style" position="right" />
      <ImageTextBlock
        preHeading="Exploatören 4D Gruppen"
        text={[
          `4D Gruppen är ett etablerat namn när det kommer till kvadratsmarta centrala bostäder. Målet är att den som flyttar in i en lägenhet skapad av oss ska bo i ett unikt och välplanerat hem men också känna igen de goda materialvalen, omvårdad exklusivitet och hållbarhet.`,
        ]}
        imagePosition="right"
        offset={2}
        buttonTitle="Mer om oss"
        buttonLink="/om-oss/"
        image={
          <StaticImage
            src="../images/om-oss.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="4D Gruppen"
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <Heading text="Galleri" level="h2withH1style" position="left" />
      <ImageTextBlock
        preHeading="Utforska det färdiga resultatet"
        text={['Efter en varsam renovering och nytänkande arkitektur står nu Smedjan färdig – en harmonisk förening av Tredje Långgatans industriella arv och modern design. Här har rustika material mött samtida formspråk för att skapa en unik atmosfär, där historien känns närvarande i varje detalj. Utforska vårt bildgalleri och låt dig inspireras av hur Porthuset och Gårdshuset fått nytt liv, med respekt för det gamla men med blicken mot framtiden.']}
        imagePosition="left"
        offset={2}
        buttonTitle="Utforska bildgalleriet"
        buttonLink="/galleri/"
        image={
          <StaticImage
            src="../images/galleri/Smedjan_04_FotoHampusBerndtson_low.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt=""
            placeholder="blurred"
            layout="fullWidth"
          />
        }
      />

      <Map />
    </Layout>
  )
}

export default IndexPage
